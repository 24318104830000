const React = require('react')
const formatBytes = require('pretty-bytes')
const orderBy = require('lodash/orderBy')
const SyntaxHighlighter = require('react-syntax-highlighter').default
const syntaxStyle = require('react-syntax-highlighter/dist/cjs/styles/hljs/github-gist').default
const getLanguageName = require('content-type-to-language-name')
const styles = require('./file-explorer.css')
const BackIcon = require('../icons/back')
// TODO: tweak svg's viewBox property to a sane default size
const FileIcon = require('../icons/file-explorer-file')
const FolderIcon = require('../icons/file-explorer-folder')
const explorerWombat = require('./img/explorer-wombat.png')

const getSupportedLanguage = contentType => getLanguageName(contentType) || ''

const isItemFolder = (item) =>
  item && item.type === 'folder'

const TreeBreadcrumb = ({ packageName, selectedFile, selectedFolder, onSelectFolder }) => {
  const breadcrumbItems = (selectedFile || selectedFolder).split('/').filter(Boolean)

  // TODO: root breadcrumb should not be a link when it's the unique item
  return (
    <div className={styles.treeBreadcrumb}>
      <h4>
        {'/'}{
          <TreeBreadcrumbItem
            path={'/'}
            label={packageName}
            onSelectFolder={onSelectFolder}
          />
        }{
          breadcrumbItems.map((item, i, arr) => (
            i === arr.length - 1 // is last item?
              ? <div className={styles.treeBreadcrumbTrailingItem} key={item}>{item}{selectedFolder ? ' /' : ''}</div>
              : <TreeBreadcrumbItem
                key={item}
                path={`/${arr.slice(0, i + 1).join('/')}/`}
                label={item}
                onSelectFolder={onSelectFolder}
              />
          ))
        }
      </h4>
    </div>
  )
}

const TreeBreadcrumbItem = ({ path, label, onSelectFolder }) => (
  <React.Fragment>
    <button onClick={() => onSelectFolder(path)}>{ label }</button>
    {'/'}
  </React.Fragment>
)

const TreeItem = ({ entry, onSelectFolder, onSelectFile }) => (
  <li className={styles.treeItem} style={{ fontWeight: entry.type === 'folder' ? 'bold' : 'inherit' }}>
    <div className={styles.treeItemName}>
      { entry.type === 'folder'
        ? <div className={styles.treeItemFolderIcon}><FolderIcon /></div>
        : <div className={styles.treeItemFileIcon}><FileIcon /></div>
      }
      {
        <button onClick={() => isItemFolder(entry) ? onSelectFolder(entry.path) : onSelectFile(entry.path)}>{ entry.label }</button>
      }
    </div>
    <div className={styles.treeItemInfo}>
      { entry.contentType }
    </div>
    <div className={styles.treeItemInfoSize}>
      { entry.size && formatBytes(entry.size) }
    </div>
  </li>
)

const TreeView = ({ currentFiles, selectedFolder, onSelectFolder, onSelectFile }) => {
  if (!currentFiles) return null

  return (
    <div>
      <ul className={styles.treeList}>
        { selectedFolder &&
            selectedFolder !== '/' &&
            <TreeItem
              entry={{
                type: 'folder',
                path: getPrevPath({ path: selectedFolder, isFolder: true }),
                label: '../'
              }}
              key='../'
              onSelectFolder={onSelectFolder}
              onSelectFile={onSelectFile}
            />
        }
        {orderBy(
          Object
            .keys(currentFiles)
            .map(currentPath => currentFiles[currentPath]),
          ['type', 'label'],
          ['desc', 'asc']
        )
          .map(entry => {
            return <TreeItem
              entry={entry}
              key={entry.path}
              onSelectFolder={onSelectFolder}
              onSelectFile={onSelectFile}
            />
          })}
      </ul>
    </div>
  )
}

const getPrevPath = ({ path, isFolder }) =>
  `${path.split('/').slice(0, -(isFolder ? 2 : 1)).join('/')}/`

const getselectedFolderSubTree = (files, selectedFolder) =>
  Object.keys(files).reduce((acc, filename) => {
    const name = filename &&
      filename.replace(new RegExp(`^${selectedFolder}`), '')
    if (!name || name === filename) return acc

    const [currentFolderName, isFolder] = name.split('/')
    const { contentType, path, size, type } = files[filename]
    const label = isFolder ? `${currentFolderName}/` : name

    acc[label] = isFolder
      ? {
        contentType: 'folder',
        label,
        path: `${selectedFolder}${currentFolderName}/`,
        size: ((acc[label] && acc[label].size) || 0) + (size || 0),
        type: 'folder'
      } : {
        contentType,
        label,
        path,
        size,
        type
      }

    return acc
  }, {})

const CodeView = ({ currentFileContent, currentFileContentType, currentFileLOC, currentFileSize, onSelectBack }) => (
  <div className={styles.codeView}>
    <div className={styles.codeViewHeader}>
      <button className={styles.codeViewBackButton} onClick={() => onSelectBack()}>
        <div className={styles.codeViewBackIcon}><BackIcon /></div>
        Back
      </button>
      <div className={styles.codeViewHeaderFileInfo}>
        <div>{ currentFileLOC } LOC</div>
        <div className={styles.codeViewHeaderItem}>{ formatBytes(currentFileSize) }</div>
      </div>
    </div>
    <SyntaxHighlighter
      language={getSupportedLanguage(currentFileContentType)}
      showLineNumbers
      style={syntaxStyle}
      lineNumberStyle={{
        paddingLeft: 10,
        color: '#8c8c8c'
      }}
    >
      { currentFileContent }
    </SyntaxHighlighter>
  </div>
)

const getNoContentTitle = code => ({
  NOORG: 'This feature is exclusive to Teams'
})[code]

const getNoContentMsg = code => ({
  NOUNPACKFILE: <div>
    <p>The package file size seems to be too large or the last published date is too old.</p>
    <p>We may support exploring this package in the future. Check back soon.</p>
  </div>,
  NOORG: <div>
    <p>The package file explorer is only available for <a href='/products/teams'>Teams</a> at the moment.</p>
    <p>We may support exploring this package in the future. Check back soon.</p>
  </div>,
  NOPACKAGE: <div>
    <p>It looks like there was a problem unpacking the file contents for this package.</p>
    <p>Please try again later.</p>
  </div>,
  NOPRIVATE: <div>
    <p>Private packages are not available to be explored at this time.</p>
    <p>Please try again later.</p>
  </div>
})[code]

const NoContent = ({ code }) => {
  if (!code) return null
  const title = getNoContentTitle(code) || 'Oh noes! Something went wrong.'

  return (
    <div className={styles.noContentContainer}>
      <div className={styles.treeBreadcrumb}>
        <h4>{ title }</h4>
      </div>
      <div className={styles.noContent}>
        <div className={styles.noContentWombat}>
          <img width='200' alt='Illustration of wombats' src={explorerWombat} />
        </div>
        <div className={styles.noContentMsg}>
          {getNoContentMsg(code)}
        </div>
      </div>
    </div>
  )
}

class FileExplorer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedFile: '',
      selectedFolder: '/'
    }
  }

  handleSelectFolder (path) {
    this.setState({
      selectedFolder: path,
      selectedFile: ''
    })
  }

  handleSelectFile (path) {
    this.setState({
      selectedFolder: null,
      selectedFile: path
    })
  }

  handleSelectBack () {
    this.setState({
      selectedFolder: getPrevPath({ path: this.state.selectedFile }),
      selectedFile: ''
    })
  }

  render () {
    const { files, packageName } = this.props
    const { selectedFile, selectedFolder } = this.state
    const { integrity, noContentCode, shasum } = files
    const currentFiles = selectedFolder && getselectedFolderSubTree(files, selectedFolder)
    const currentFile = files[selectedFile]
    const currentFileContent = currentFile && currentFile.content
    const currentFileContentType = currentFile && currentFile.contentType
    const currentFileLOC = currentFile && currentFile.loc
    const currentFileSize = currentFile && currentFile.size

    return (
      <div>
        <div className={styles.fileExplorer}>
          {
            !noContentCode &&
              <TreeBreadcrumb
                packageName={packageName}
                selectedFolder={selectedFolder}
                selectedFile={selectedFile}
                onSelectFolder={path => this.handleSelectFolder(path)}
              />
          }
          { currentFiles &&
          <TreeView
            currentFiles={currentFiles}
            selectedFolder={selectedFolder}
            onSelectFolder={path => this.handleSelectFolder(path)}
            onSelectFile={path => this.handleSelectFile(path)}
          />
          }
          {
            currentFileContent &&
              <CodeView
                currentFileContentType={currentFileContentType}
                currentFileContent={currentFileContent}
                currentFileLOC={currentFileLOC}
                currentFileSize={currentFileSize}
                onSelectBack={() => this.handleSelectBack()}
              />
          }
          {
            noContentCode &&
              <NoContent code={noContentCode} />
          }
        </div>
        <div className={styles.shasumInfo}>
          {
            integrity &&
              <div><strong>Integrity:</strong> <div className={styles.shasumItem}>{ integrity }</div></div>
          }
          {
            shasum &&
              <div><strong>Shasum:</strong> <div className={styles.shasumItem}>{ shasum }</div></div>
          }
        </div>
      </div>
    )
  }
}

module.exports = FileExplorer
