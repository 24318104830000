const React = require('react')
const PropTypes = require('prop-types')
const { Helmet } = require('react-helmet')
const Markdown = require('../markdown/markdown')
require('github-syntax-light/lib/github-light.css')
const styles = require('../../styles/package.css')

class Readme extends React.PureComponent {
  render () {
    const { id, data, showReadme } = this.props
    return (
      <div className={showReadme ? '' : styles.readmeHidden}>
        <Helmet>
          <link rel='stylesheet' href={require('./markdown.css')} />
        </Helmet>
        <article> { /* in order to improve snippet generation in Google */ }
          <Markdown
            markdown={data}
            id={id} />
        </article>
      </div>
    )
  }
}

Readme.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  starAction: PropTypes.string.isRequired
}

module.exports = Readme
