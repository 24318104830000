'use strict'

const React = require('react')
const StaticComponent = require('../static')
const styles = require('./icons.css')

class FolderIcon extends StaticComponent {
  render () {
    return (
      <img className={styles.folderIcon} src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA5OCAxMjIuNSIgZmlsbD0iIzY5Njk2OSI+PHBhdGggZD0iTTM3LjkgMTguNEgxM3Y2MS4yaDcyVjI1SDQ5ek03OSA3My42SDE5VjQ0aDYwek03OSAzOEgxOVYyNC40aDE3LjJMNDcuMyAzMUg3OXoiLz48L3N2Zz4=' />
    )
  }
}

module.exports = FolderIcon
