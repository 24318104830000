const React = require('react')
const styles = require('./markdown.css')

function Markdown ({ markdown, id, className = '' }) {
  return (
    <div
      className={`${styles.markdown} ${className} markdown`}
      id={id}
      dangerouslySetInnerHTML={{ __html: markdown }} />
  )
}

module.exports = Markdown
