const React = require('react')
const Link = require('@npm/spiferack/link')
const styles = require('./dependency.css')

class Dependency extends React.PureComponent {
  render () {
    const { package: pkg } = this.props
    return <li className='dib mr2'>
      <Link href={`/package/${encodeURIComponent(pkg)}`}>
        <a className={styles.sectionWordCloud}>
          {pkg}
        </a>
      </Link>
    </li>
  }
}

module.exports = Dependency
