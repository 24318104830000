'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const styles = require('../../styles/package.css')
const GitIcon = require('../icons/git')
const GitHubIcon = require('../icons/github')

class RepoLink extends React.Component {
  render () {
    const { repoLink, children } = this.props

    return <div className={`${styles.sidebarSection} w-100`}>
      <h3 id='repository' className={styles.sidebarHeader}>Repository</h3>
      <p className={styles.sidebarTextSmall}>
        {repoLink.type === 'github' ? (
          <a
            aria-labelledby='repository repository-link'
            className={styles.sidebarLink}
            href={`https://github.com/${repoLink.repo}`}
            target='_blank'
            rel='noopener noreferrer nofollow'>
            <span className={styles.repoLinkIcon}><GitHubIcon /></span>
            <span id='repository-link'>{repoLink.repo}</span>
          </a>
        ) : (
          <a
            aria-labelledby='repository repository-link'
            className={styles.sidebarLink}
            href={repoLink.repo}
            target='_blank'
            rel='noopener noreferrer nofollow'>
            <span className={styles.repoLinkIcon}><GitIcon /></span>
            <span id='repository-link'>{repoLink.repo.replace(/(^\w+:|^)\/\//, '')}</span>
          </a>
        )}
      </p>
      {children}
    </div>
  }
}

RepoLink.propTypes = {
  repoLink: PropTypes.exact({
    type: PropTypes.string.isRequired,
    repo: PropTypes.string.isRequired
  }).isRequired
}

module.exports = RepoLink
