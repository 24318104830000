'use strict'
const PropTypes = require('prop-types')
const React = require('react')

const pluralize = require('../../utils/pluralize')
const ArchiveIcon = require('../icons/archive')
const CogIcon = require('../icons/cog')
const BoxIcon = require('../icons/box')
const BoxesIcon = require('../icons/boxes')
const FileAltIcon = require('../icons/file-alt')
const TagIcon = require('../icons/tag')
const styles = require('../tabs/tabs.css')
const Tabs = require('../tabs/tabs')

const BetaTag = () => (
  <span className={styles.tabBetaTag}>BETA</span>
)

function PackageTabs (props) {
  const {
    dependentsCount,
    dependenciesCount,
    files,
    versionsCount,
    active,
    isMaintainer,
    packageUrl,
    codeExploreV2
  } = props

  const isAdmin = active === 'admin'
  // TODO: re-enable the admin tab once the UI works consistently for orgs
  const showAdminTab = isMaintainer
  const showFileExplorerTab = files !== null || codeExploreV2 // files will be null when !settings.IS_FILE_EXPLORER_ENABLED

  const links = [
    {
      key: 'readme',
      href: isAdmin ? packageUrl : null,
      label: <span><FileAltIcon /> Readme</span>
    },
    (showFileExplorerTab && {
      key: 'explore',
      href: isAdmin ? `${packageUrl}?activeTab=explore` : null,
      label: <span><span><ArchiveIcon /> Explore </span><BetaTag /></span>
    }),
    {
      key: 'dependencies',
      href: isAdmin ? `${packageUrl}?activeTab=dependencies` : null,
      label: <span>
        <BoxIcon />
        {dependenciesCount.toLocaleString() + ' ' + pluralize(dependenciesCount)`Dependenc${['ies', 'y']}`}
      </span>
    },
    {
      key: 'dependents',
      href: isAdmin ? `${packageUrl}?activeTab=dependents` : null,
      label: <span>
        <BoxesIcon />
        {`${dependentsCount.toLocaleString()} Dependents`}
      </span>
    },
    {
      key: 'versions',
      href: isAdmin ? `${packageUrl}?activeTab=versions` : null,
      label: <span>
        <TagIcon />
        {`${versionsCount.toLocaleString()} Versions`}
      </span>
    },
    (showAdminTab && {
      key: 'admin',
      href: `${packageUrl}/access`,
      label: <span><CogIcon /> Settings</span>
    })
  ].filter(Boolean)
  return <Tabs
    links={links}
    colors={['yellow', 'red', 'purple', 'violet', 'teal', 'red']}
    active={active} />
}

PackageTabs.propTypes = {
  files: PropTypes.object,
  dependentsCount: PropTypes.number.isRequired,
  dependenciesCount: PropTypes.number.isRequired,
  versionsCount: PropTypes.number.isRequired,
  isMaintainer: PropTypes.bool,
  active: PropTypes.string
}

PackageTabs.defaultProps = {
  dependentsCount: 0,
  dependenciesCount: 0,
  versionsCount: 0,
  isMaintainer: false,
  active: 'readme'
}

module.exports = PackageTabs
