function License ({ license }) {
  if (!license) return 'none'
  if (Array.isArray(license)) {
    return license.map(license => License({ license })).join(', ')
  }
  if (typeof license === 'string') return license
  if (typeof license === 'object' && Object.keys(license).length !== 0) {
    return license.type || license.name || license.license || 'none'
  }
  return 'none'
}

module.exports = License
