'use strict'
const React = require('react')
const styles = require('./versions.css')
const PropTypes = require('prop-types')
const Time = require('../time')
const packageStyles = require('../../styles/package.css')
const Checkbox = require('../inputs/checkbox').unwrapped
const Link = require('@npm/spiferack/link')

class PackageVersions extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = { showDeprecated: false }
  }

  toggleDeprecated () {
    this.setState({ showDeprecated: !this.state.showDeprecated })
  }

  versionList (versions) {
    const { name, versionsDownloads = {} } = this.props
    return (
      <ul className={styles.list}>
        <li>
          <span>Version</span>
          <div className={styles.spacer} />
          <span>Downloads (Last 7 Days)</span>
          <ul className={`${styles.list} ${styles['list-narrow']}`}>
            <li>
              <div className={styles.spacer} />
              <span>{versions && versions[0] && versions[0].tag ? 'Tag' : 'Published'}</span>
            </li>
          </ul>
        </li>
        {
          this.sortIfCurrentTags(versions).map(({ version, deprecated, date, tag }) => (
            <li key={tag || version} className={deprecated ? styles.deprecated : ''}>
              <Link>
                <a href={`/package/${name}/v/${version}`} className={styles.versions} title={deprecated ? `deprecated: ${deprecated}` : version}>{version}</a>
              </Link>
              <div className={styles.spacer} />
              <code className='downloads'>{versionsDownloads[version] !== undefined ? versionsDownloads[version].toLocaleString() : '0'}</code>
              <ul className={`${styles.list} ${styles['list-narrow']}`}>
                <li>
                  <div className={styles.spacer} />
                  <code className={styles.tag}>{tag || <Time at={date} />}</code>
                </li>
              </ul>
            </li>
          ))
        }
      </ul>
    )
  }

  sortIfCurrentTags (versions) {
    const { versionsDownloads = {} } = this.props
    if (versions && versions[0] && versions[0].tag) { // if we are in current tags, sort
      return versions.sort((a, b) => {
        if (versionsDownloads[a.version] !== versionsDownloads[b.version]) {
          return versionsDownloads[b.version] - versionsDownloads[a.version]
        } else {
          return ('' + b.version).localeCompare(a.version)
        }
      })
    }
    return versions
  }

  render () {
    const { distTags, versions = [], deprecations } = this.props
    const { showDeprecated } = this.state
    const hasDeprecatedVersions = versions.some(({ deprecated }) => deprecated)
    const taggedVersions = Object.keys(distTags).filter(t => !deprecations.includes(distTags[t])).map(tag => {
      return Object.assign({}, versions.find(v => v.version === distTags[tag]), { tag: tag })
    })

    return <div className={styles['versions-container']}>
      <div className={`${packageStyles.infoBox} w-100`}>
        <strong>Tip:</strong> Click on a version number to view a previous version's package page
      </div>
      <h3 className={packageStyles.sectionHeader}>Current Tags</h3>
      {
        this.versionList(taggedVersions)
      }
      <h3 className={packageStyles.sectionHeader}>Version History</h3>
      {hasDeprecatedVersions && <Checkbox
        dispatch={() => {}}
        label='show deprecated versions'
        formId='fake'
        name='showDeprecated'
        onChange={() => this.toggleDeprecated()}
        formData={{ value: showDeprecated }} />
      }
      {
        this.versionList(
          versions.filter(({ deprecated }) => showDeprecated || !deprecated)
        )
      }
    </div>
  }
}

PackageVersions.propTypes = {
  distTags: PropTypes.object.isRequired,
  versions: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.object,
    version: PropTypes.string
  })).isRequired,
  deprecations: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  versionsDownloads: PropTypes.shape({
    [PropTypes.string]: PropTypes.number
  })
}

PackageVersions.defaultProps = {}

module.exports = PackageVersions
