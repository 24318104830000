
  const __registry__ = require('../../../spiferack/client/index.js')
  'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Link = require('@npm/spiferack/link');

var _require = require('react-helmet'),
    Helmet = _require.Helmet;

var _require2 = require('lodash'),
    find = _require2.find;

var PropTypes = require('prop-types');
var React = require('react');
var depCss = require('../../components/package/dependency.css');
var Dependency = require('../../components/package/dependency');
var Versions = require('../../components/package/versions');
var Admin = require('../../components/package/tabs/admin');
var Sidebar = require('../../components/package/sidebar');
var PackageTabs = require('../../components/package/tabs');
var Readme = require('../../components/package/readme');
var FileExplorer = require('../../components/package/file-explorer');
var FileExplorerV2 = require('../../components/package/file-explorer-v2');
var connect = require('../../components/connect');
var styles = require('../../styles/package.css');
var Time = require('../../components/time');
var TypesIcon = require('../../components/package/types-icon');

var _require3 = require('../../components/package-linking/banners'),
    PackagePageInitiateAutolinkingBanner = _require3.PackagePageInitiateAutolinkingBanner;

var Package = function (_React$PureComponent) {
  _inherits(Package, _React$PureComponent);

  function Package(props) {
    _classCallCheck(this, Package);

    var _this = _possibleConstructorReturn(this, (Package.__proto__ || Object.getPrototypeOf(Package)).call(this, props));

    _this.state = {
      dialogIsOpen: false
    };
    return _this;
  }

  _createClass(Package, [{
    key: 'setDialogOpen',
    value: function setDialogOpen(open) {
      this.setState({ dialogIsOpen: open });
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          _props$activeTab = _props.activeTab,
          activeTab = _props$activeTab === undefined ? 'readme' : _props$activeTab,
          canEditPackage = _props.canEditPackage,
          capsule = _props.capsule,
          dependents = _props.dependents,
          downloads = _props.downloads,
          files = _props.files,
          ghapi = _props.ghapi,
          pkg = _props.package,
          packageLinkingCallToActionHref = _props.packageLinkingCallToActionHref,
          packageUrl = _props.packageUrl,
          packageVersion = _props.packageVersion,
          packument = _props.packument,
          isPrivate = _props.private,
          linkedRepository = _props.repository,
          linkingAllowedForPackage = _props.linkingAllowedForPackage,
          scope = _props.scope,
          _props$tabClicked = _props.tabClicked,
          tabClicked = _props$tabClicked === undefined ? false : _props$tabClicked,
          codeExploreV2 = _props.codeExploreV2,
          _props$isSecurityPlac = _props.isSecurityPlaceholder,
          isSecurityPlaceholder = _props$isSecurityPlac === undefined ? false : _props$isSecurityPlac;
      var maintainers = packument.maintainers,
          deprecations = packument.deprecations,
          description = packument.description,
          keywords = packument.keywords,
          _packument$distTags = packument.distTags,
          distTags = _packument$distTags === undefined ? {} : _packument$distTags,
          _packument$versions = packument.versions,
          versions = _packument$versions === undefined ? [] : _packument$versions,
          funding = packument.funding,
          homepage = packument.homepage,
          packumentLicense = packument.license,
          repository = packument.repository;


      var repoLink = linkedRepository ? {
        type: 'github',
        repo: linkedRepository
      } : repository ? {
        type: 'other',
        repo: repository
      } : null;

      var _ref = packageVersion || {},
          _ref$name = _ref.name,
          name = _ref$name === undefined ? packument.name : _ref$name,
          version = _ref.version,
          dependencies = _ref.dependencies,
          devDependencies = _ref.devDependencies,
          deprecated = _ref.deprecated,
          packageVersionLicense = _ref.license;

      var latest = distTags.latest;
      var isLatest = version === latest;
      var isLatestDeprecated = isLatest && deprecated;

      var packageInfo = versions.length !== 0 ? find(versions, ['version', version]) : undefined;
      var dist = packageInfo ? packageInfo.dist : {};
      var deps = Object.keys(dependencies || {});
      var devDeps = Object.keys(devDependencies || {});
      var currentVersion = versions.find(function (el) {
        return el.version === version;
      });
      var publishDate = currentVersion && currentVersion.date;
      var latestVersion = versions.find(function (el) {
        return el.version === latest;
      });
      var latestPublishDate = latestVersion && latestVersion.date;

      return [
      // Show the call to action
      React.createElement(
        'div',
        { className: styles.root, id: 'top', key: 'package' },
        packageLinkingCallToActionHref && React.createElement(
          'div',
          { className: styles.callToActionContainer },
          React.createElement(PackagePageInitiateAutolinkingBanner, { ghAppInstallHref: packageLinkingCallToActionHref })
        ),
        deprecated && React.createElement(
          'div',
          { className: styles.noticeContainer },
          React.createElement(
            'h1',
            { className: styles.noticeHeading },
            'This ',
            isLatestDeprecated ? 'package' : 'version',
            ' has been deprecated'
          ),
          typeof deprecated === 'string' && React.createElement(
            'span',
            null,
            React.createElement(
              'p',
              { className: styles.noticeSubHeading },
              'Author message:'
            ),
            React.createElement(
              'code',
              { className: styles.noticeNote },
              deprecated
            )
          )
        ),
        React.createElement(Head, { name: pkg, packageDescription: description, latest: latest, latestPublishDate: latestPublishDate, dependentsCount: dependents.dependentsCount, keywords: keywords, isSecurityPlaceholder: isSecurityPlaceholder }),
        React.createElement(
          'div',
          { className: 'w-100 ph0-l ph3 ph4-m' },
          React.createElement(
            'h2',
            { className: styles.packageName },
            React.createElement(
              'span',
              { className: styles.name, title: name },
              name
            ),
            React.createElement(TypesIcon, { pkgVersion: packageVersion, capsule: capsule })
          ),
          React.createElement(
            'span',
            { className: styles.meta },
            version,
            '\xA0\u2022\xA0'
          ),
          React.createElement(
            'span',
            { className: styles.metaGreen },
            isPrivate ? 'Private' : 'Public'
          ),
          publishDate && React.createElement(
            'span',
            { className: styles.meta },
            '\xA0\u2022\xA0Published ',
            React.createElement(Time, { at: publishDate })
          )
        ),
        React.createElement(PackageTabs, {
          active: activeTab,
          files: files,
          dependenciesCount: deps.length,
          dependentsCount: dependents.dependentsCount,
          versionsCount: versions.length,
          isMaintainer: canEditPackage,
          packageUrl: packageUrl,
          codeExploreV2: codeExploreV2 }),
        React.createElement(
          'div',
          { className: styles.main },
          React.createElement(
            'span',
            null,
            !tabClicked && this.renderTabContent(keywords, name, deps, version, devDeps, versions, distTags, deprecations, deprecated)
          ),
          React.createElement(
            'span',
            { 'aria-live': 'polite' },
            tabClicked && this.renderTabContent(keywords, name, deps, version, devDeps, versions, distTags, deprecations, deprecated)
          )
        ),
        React.createElement(Sidebar, {
          name: name,
          downloads: downloads,
          version: version,
          license: packageVersionLicense || packumentLicense,
          homepage: homepage,
          repoLink: repoLink,
          funding: funding,
          versions: versions,
          maintainers: maintainers,
          pkg: pkg,
          dist: dist,
          isLatest: isLatest,
          ghapi: ghapi,
          canEditPackage: canEditPackage,
          'private': isPrivate,
          scope: scope,
          linkingAllowedForPackage: linkingAllowedForPackage
        })
      )];
    }
  }, {
    key: 'renderTabContent',
    value: function renderTabContent(keywords, name, deps, version, devDeps, versions, distTags, deprecations, deprecated) {
      var _props2 = this.props,
          _props2$activeTab = _props2.activeTab,
          activeTab = _props2$activeTab === undefined ? 'readme' : _props2$activeTab,
          canEditPackage = _props2.canEditPackage,
          user = _props2.user,
          files = _props2.files,
          pkg = _props2.package,
          readme = _props2.readme,
          versionsDownloads = _props2.versionsDownloads,
          dependents = _props2.dependents,
          isDeletable = _props2.isDeletable,
          isStarred = _props2.isStarred,
          isPrivate = _props2.private,
          starAction = _props2.starAction,
          _props2$formData = _props2.formData,
          formData = _props2$formData === undefined ? {} : _props2$formData,
          codeExploreV2 = _props2.codeExploreV2;


      var showAdmin = canEditPackage && activeTab === 'admin';
      var showDependencies = activeTab === 'dependencies';
      var showDependents = activeTab === 'dependents';
      var showFileExplorer = activeTab === 'explore';
      var showVersions = activeTab === 'versions';
      // Show README by default
      var showReadme = !showAdmin && !showDependencies && !showDependents && !showFileExplorer && !showVersions;

      return [React.createElement(
        'section',
        { className: styles.container, key: 'tabpanel-readme', id: 'tabpanel-readme', 'aria-labelledby': 'package-tab-readme', role: 'tabpanel', 'data-attribute': showReadme ? '' : 'hidden' },
        readme && React.createElement(ReadmeTab, {
          user: user,
          pkg: pkg,
          readme: readme,
          isStarred: isStarred,
          starAction: starAction,
          formData: formData,
          keywords: keywords,
          showReadme: showReadme })
      ), React.createElement(
        'section',
        { className: styles.container, key: 'tabpanel-explore', id: 'tabpanel-explore', 'aria-labelledby': 'package-tab-explore', role: 'tabpanel', 'data-attribute': showFileExplorer ? '' : 'hidden' },

        // files will be null when !settings.IS_FILE_EXPLORER_ENABLED
        showFileExplorer && files && !codeExploreV2 && React.createElement(FileExplorer, { packageName: name, files: files }),
        showFileExplorer && codeExploreV2 && React.createElement(FileExplorerV2, { packageName: name, packageVersion: version })
      ), React.createElement(
        'section',
        { className: styles.container, key: 'tabpanel-admin', id: 'tabpanel-admin', 'aria-labelledby': 'package-tab-admin', role: 'tabpanel', 'data-attribute': showAdmin ? '' : 'hidden' },
        showAdmin && React.createElement(Admin, {
          pkg: pkg,
          user: user,
          maintainers: this.props.maintainers,
          invitations: this.props.invitations,
          'private': isPrivate,
          formData: formData,
          changeAction: this.props.changeAction,
          canBePrivate: this.props.canBePrivate,
          canAddMaintainers: this.props.canAddMaintainers,
          canRemoveMaintainers: this.props.canRemoveMaintainers,
          isPackageInviteFFEnabled: this.props.isPackageInviteFFEnabled,
          isDeletable: isDeletable,
          deprecated: deprecated
        })
      ), React.createElement(
        'section',
        { className: styles.container, key: 'tabpanel-dependencies', id: 'tabpanel-dependencies', 'aria-labelledby': 'package-tab-dependencies', role: 'tabpanel', 'data-attribute': showDependencies ? '' : 'hidden' },
        showDependencies && React.createElement(Dependencies, { deps: deps, devDeps: devDeps })
      ), React.createElement(
        'section',
        { className: styles.container, key: 'tabpanel-dependents', id: 'tabpanel-dependents', 'aria-labelledby': 'package-tab-dependents', role: 'tabpanel', 'data-attribute': showDependents ? '' : 'hidden' },
        showDependents && React.createElement(Dependents, { pkg: pkg, dependents: dependents })
      ), React.createElement(
        'div',
        { className: styles.container, key: 'tabpanel-versions', id: 'tabpanel-versions', 'aria-labelledby': 'package-tab-versions', role: 'tabpanel', 'data-attribute': showVersions ? '' : 'hidden' },
        showVersions && React.createElement(Versions, { name: name, distTags: distTags, versions: versions, deprecations: deprecations, versionsDownloads: versionsDownloads })
      )];
    }
  }]);

  return Package;
}(React.PureComponent);

function Head(_ref2) {
  var name = _ref2.name,
      packageDescription = _ref2.packageDescription,
      latest = _ref2.latest,
      latestPublishDate = _ref2.latestPublishDate,
      dependentsCount = _ref2.dependentsCount,
      _ref2$keywords = _ref2.keywords,
      keywords = _ref2$keywords === undefined ? [] : _ref2$keywords,
      _ref2$isSecurityPlace = _ref2.isSecurityPlaceholder,
      isSecurityPlaceholder = _ref2$isSecurityPlace === undefined ? false : _ref2$isSecurityPlace;

  var description = generateDescription(name, packageDescription, latest, latestPublishDate, dependentsCount);

  return React.createElement(
    Helmet,
    null,
    React.createElement(
      'title',
      null,
      name,
      ' - npm'
    ),
    React.createElement('meta', { name: 'description', content: description }),
    React.createElement('meta', { property: 'og:description', content: description }),
    React.createElement('meta', { property: 'og:title', content: name }),
    React.createElement('meta', { property: 'og:url', content: 'https://www.npmjs.com/package/' + name }),
    isSecurityPlaceholder && React.createElement('meta', { name: 'robots', content: 'noindex' }),
    React.createElement('meta', { property: 'og:site_name', content: 'npm' }),
    React.createElement('meta', { name: 'keywords', content: keywords.join() }),
    React.createElement('meta', { name: 'twitter:card', content: 'summary' }),
    React.createElement('meta', { name: 'twitter:url', content: 'https://www.npmjs.com/package/' + name }),
    React.createElement('meta', { name: 'twitter:title', content: 'npm: ' + name }),
    React.createElement('meta', { name: 'twitter:description', content: description })
  );
}

/**
 * @param {string} description
 * @param {string} name
 * @param {string} latestVersion
 * @param {{ ts: string, rel: string }} latestPublishDate
 * @param {number} dependentsCount
 */
function generateDescription(name, description, latestVersion, latestPublishDate, dependentsCount) {
  var sentences = [];

  if (description) {
    sentences.push(description + '.');
  }

  sentences.push('Latest version: ' + latestVersion + ', last published: ' + (latestPublishDate && latestPublishDate.rel || 'unknown') + '.');
  sentences.push('Start using ' + name + ' in your project by running `npm i ' + name + '`.');
  if (!dependentsCount) {
    sentences.push('There are no other projects in the npm registry using ' + name + '.');
  } else if (dependentsCount === 1) {
    sentences.push('There is 1 other project in the npm registry using ' + name + '.');
  } else {
    sentences.push('There are ' + dependentsCount + ' other projects in the npm registry using ' + name + '.');
  }

  return sentences.join(' ');
}

function ReadmeTab(props) {
  var user = props.user,
      pkg = props.pkg,
      readme = props.readme,
      isStarred = props.isStarred,
      starAction = props.starAction,
      formData = props.formData,
      keywords = props.keywords,
      showReadme = props.showReadme;

  var keywordsDiv = null;

  if (showReadme) {
    keywordsDiv = React.createElement(
      'div',
      { className: 'pv4', key: 'keywords' },
      React.createElement(
        'h2',
        { id: 'user-content-keywords', className: styles.sectionHeader },
        'Keywords'
      ),
      keywords && keywords.length ? React.createElement(
        'ul',
        { className: 'list pl0' },
        keywords.map(function (word, idx) {
          return React.createElement(
            'li',
            { key: word, className: 'dib mr2' },
            React.createElement(
              Link,
              null,
              React.createElement(
                'a',
                { className: depCss.sectionWordCloud, href: '/search?q=keywords:' + word },
                word
              )
            )
          );
        })
      ) : 'none'
    );
  }
  return [React.createElement(Readme, {
    key: 'readme',
    user: user,
    pkg: pkg,
    id: readme.ref,
    data: readme.data,
    isStarred: isStarred,
    starAction: starAction,
    formData: formData,
    showReadme: showReadme }), keywordsDiv];
}

function Dependencies(_ref3) {
  var deps = _ref3.deps,
      devDeps = _ref3.devDeps;

  return [React.createElement(
    'h2',
    { className: styles.sectionHeader, key: 'title' },
    'Dependencies (',
    deps.length,
    ')'
  ), React.createElement(
    'ul',
    { role: 'list', className: 'list pl0', 'aria-label': 'Dependencies', key: 'deps' },
    deps.map(function (xs, idx) {
      return React.createElement(Dependency, { key: xs, 'package': xs });
    })
  ), React.createElement(
    'h2',
    { className: styles.sectionHeader, key: 'title2' },
    'Dev Dependencies (',
    devDeps.length,
    ')'
  ), React.createElement(
    'ul',
    { role: 'list', className: 'list pl0', 'aria-label': 'Dev Dependencies', key: 'devDeps' },
    devDeps.map(function (xs, idx) {
      return React.createElement(Dependency, { key: xs, 'package': xs });
    })
  )];
}

function Dependents(_ref4) {
  var pkg = _ref4.pkg,
      dependents = _ref4.dependents;

  return [React.createElement(
    'h2',
    { className: styles.sectionHeader, key: 'title' },
    'Dependents\u2002',
    React.createElement(
      Link,
      null,
      React.createElement(
        'a',
        { className: styles.link, href: '/browse/depended/' + pkg },
        '(',
        dependents.dependentsCount,
        ')'
      )
    )
  ), React.createElement(DependencyList, { count: dependents.dependentsCount, list: dependents.dependentsTruncated })];

  function DependencyList(_ref5) {
    var count = _ref5.count,
        list = _ref5.list;

    return count > 0 ? React.createElement(
      'ul',
      { className: 'list pl0', key: 'content' },
      list.map(function (xs, idx) {
        return React.createElement(Dependency, { key: xs, 'package': xs });
      }),
      count > list.length && React.createElement(
        'li',
        null,
        React.createElement(
          Link,
          null,
          React.createElement(
            'a',
            { href: '/browse/depended/' + pkg, className: depCss.sectionWordCloud },
            'and more...'
          )
        )
      )
    ) : null;
  }
}

Package.propTypes = {
  activeTab: PropTypes.string,
  canEditPackage: PropTypes.bool,
  capsule: PropTypes.object,
  dependents: PropTypes.object,
  downloads: PropTypes.array,
  files: PropTypes.object,
  ghapi: PropTypes.string,
  isDeletable: PropTypes.bool,
  isStarred: PropTypes.bool,
  package: PropTypes.string,
  packageLinkingCallToActionHref: PropTypes.string,
  packageUrl: PropTypes.string,
  packageVersion: PropTypes.object,
  packument: PropTypes.object,
  private: PropTypes.bool,
  readme: PropTypes.oneOfType([PropTypes.shape({
    data: PropTypes.string,
    ref: PropTypes.string
  }), PropTypes.string]),
  repository: PropTypes.string,
  starAction: PropTypes.string,
  tabClicked: PropTypes.bool
};

module.exports = connect()(Package);
  let __hot__
  
  __registry__.register('package/package', module.exports, __hot__)
  