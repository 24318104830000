'use strict'

const Link = require('@npm/spiferack/link')
const React = require('react')

const GithubStats = require('./github-stats')
const Downloads = require('./downloads')
const License = require('./license')
const bytesToSize = require('pretty-bytes')
const FlagIcon = require('../icons/flag')
const InstallIcon = require('../icons/install')
const CopyIcon = require('../icons/copy')
const LinkIcon = require('../icons/link')
const ReplIcon = require('../icons/repl')
const HeartIcon = require('../icons/heart')
const EZCopy = require('../../components/ez-copy/ez-copy')
const Avatar = require('@npm/design-system/avatar/avatar')
const styles = require('../../styles/package.css')
const forms = require('../../styles/forms.css')
const RepoLink = require('./repo-link')
const Time = require('../../components/time')
const PackageLinkingButton = require('../package-linking/package-linking-button')

function Sidebar (props) {
  const {
    name,
    downloads,
    version,
    license,
    homepage,
    repoLink,
    funding,
    dist,
    versions = [],
    maintainers,
    pkg,
    ghapi,
    isLatest,
    private: isPrivate,
    scope,
    linkingAllowedForPackage
  } = props

  const packageName = isLatest ? name : `${name}@${version}`

  return <div className={styles.rightSidebar}>
    <h3 className={styles.sidebarHeader}>Install</h3>
    <p className={`${styles.sidebarCode} ${styles.install} flex flex-row`}>
      <InstallIcon />
      <code className='flex-auto truncate db' title='Copy Command to Clipboard'>
        <EZCopy aria-label={`Install, npm i ${packageName}`}>
          npm i {packageName}
        </EZCopy>
      </code>
      <CopyIcon />
    </p>
    {repoLink && <RepoLink repoLink={repoLink}>
      {linkingAllowedForPackage &&
      <PackageLinkingButton
        className={styles.linkingButton}
        package={{ name }}
        scope={scope} />
      }</RepoLink>}
    {homepage && <div className={`${styles.sidebarSection} w-100`}>
      <h3 id='homePage' className={styles.sidebarHeader}>Homepage</h3>
      <p className={styles.sidebarTextSmall}>
        <a
          aria-labelledby='homePage homePage-link'
          className={styles.sidebarLink}
          rel='noopener noreferrer nofollow'
          href={homepage}>
          <LinkIcon />
          <span id='homePage-link'>{homepage.replace(/(^\w+:|^)\/\//, '')}</span>
        </a>
      </p>
    </div>}
    {funding && <div className={styles.buttons}>
      <Link>
        <a
          className={`${forms.buttonGradient} mt2 dib w-100`}
          rel='noopener noreferrer nofollow'
          href={funding.url}>
          <HeartIcon />
          <strong>Fund</strong> this package
        </a>
      </Link>
    </div>}
    {isLatest && <div>
      {(downloads && downloads.length) ? <Downloads data={downloads} key={pkg} /> : null}
    </div>}
    <div className={styles.sidebarSection}>
      <h3 className={styles.sidebarHeader}>Version</h3>
      <p className={styles.sidebarText}>{version}</p>
    </div>
    <div className={styles.sidebarSection}>
      <h3 className={styles.sidebarHeader}>License</h3>
      <p className={styles.sidebarText}><License license={license} /></p>
    </div>
    {dist.unpackedSize && <div className={styles.sidebarSection}>
      <h3 className={styles.sidebarHeader}>Unpacked Size</h3>
      <p className={styles.sidebarText}>
        {bytesToSize(dist.unpackedSize)}
      </p>
    </div>}
    {dist.fileCount && <div className={styles.sidebarSection}>
      <h3 className={styles.sidebarHeader}>Total Files</h3>
      <p className={styles.sidebarText}>
        {dist.fileCount}
      </p>
    </div>}
    {ghapi && <GithubStats ghapi={ghapi} key={pkg} />}
    {(Boolean(versions.length) && versions[0] && versions[0].date) &&
    <div className={`${styles.sidebarSection} w-100`}>
      <h3 className={styles.sidebarHeader}>Last publish</h3>
      <p className={styles.sidebarText}>
        <Time at={versions[0].date} />
      </p>
    </div>}
    {maintainers && <div className={`w-100`}>
      <h3 id='collaborators' className={styles.sidebarHeader}>Collaborators</h3>
      <ul aria-labelledby='collaborators' className='list pl0 cf'>
        {
          maintainers.map(xs => <li key={xs.name} className={`${styles.maintainer} fl dib mr2 mb2`}>
            <Avatar src={xs.avatars.medium} size={42} href={`/~${xs.name}`} title={xs.name} />
          </li>)
        }
      </ul>
    </div>}
    <div className='w-100'>
      <Link>
        <a
          className={`${forms.buttonGradientGreen} mt2 dib w-100`}
          rel='noopener noreferrer nofollow'
          href={`https://runkit.com/npm/${encodeURIComponent(name)}`}>
          <ReplIcon />
          <strong>Try</strong> on RunKit
        </a>
      </Link>
    </div>
    {!isPrivate && <div className={styles.buttons}>
      <Link>
        <a
          className={`${forms.buttonGradientRed} mt2 dib w-100`}
          href={`/support?inquire=security&security-inquire=malware&package=${encodeURIComponent(name)}&version=${version}`}>
          <FlagIcon />
          <strong>Report</strong> malware
        </a>
      </Link>
    </div>}
  </div>
}

module.exports = Sidebar
