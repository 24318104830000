const React = require('react')
const formatBytes = require('pretty-bytes')
const orderBy = require('lodash/orderBy')
const SyntaxHighlighter = require('react-syntax-highlighter').default
const syntaxStyle = require('react-syntax-highlighter/dist/cjs/styles/hljs/github-gist').default
const getLanguageName = require('content-type-to-language-name')
const styles = require('./file-explorer.css')
const stylesV2 = require('./file-explorer-v2.css')
const forms = require('../../styles/forms.css')
const BackIcon = require('../icons/back')
// TODO: tweak svg's viewBox property to a sane default size
const FileIcon = require('../icons/file-explorer-file')
const FolderIcon = require('../icons/file-explorer-folder')
const Spinner = require('../spinner/spinner')

const FETCH_INDEX_RETRY_INTERVAL = 15000 // 15 seconds in milliseconds
const getSupportedLanguage = contentType => getLanguageName(contentType) || ''

const isItemFolder = (item) =>
  item && item.type === 'folder'

const TreeBreadcrumb = ({ packageName, selectedFile, selectedFolder, onSelectFolder }) => {
  const breadcrumbItems = (selectedFile || selectedFolder).split('/').filter(Boolean)

  // TODO: root breadcrumb should not be a link when it's the unique item
  return (
    <div className={styles.treeBreadcrumb}>
      <h4>
        {'/'}{
          <TreeBreadcrumbItem
            path={'/'}
            label={packageName}
            onSelectFolder={onSelectFolder}
          />
        }{
          breadcrumbItems.map((item, i, arr) => (
            i === arr.length - 1 // is last item?
              ? <div className={styles.treeBreadcrumbTrailingItem} key={item}>{item}{selectedFolder ? ' /' : ''}</div>
              : <TreeBreadcrumbItem
                key={item}
                path={`/${arr.slice(0, i + 1).join('/')}/`}
                label={item}
                onSelectFolder={onSelectFolder}
              />
          ))
        }
      </h4>
    </div>
  )
}

const TreeBreadcrumbItem = ({ path, label, onSelectFolder }) => (
  <React.Fragment>
    <button onClick={() => onSelectFolder(path)}>{ label }</button>
    {'/'}
  </React.Fragment>
)

const TreeItem = ({ entry, onSelectFolder, onSelectFile }) => (
  <li className={styles.treeItem} style={{ fontWeight: entry.type === 'folder' ? 'bold' : 'inherit' }}>
    <div className={styles.treeItemName}>
      { entry.type === 'folder'
        ? <div className={styles.treeItemFolderIcon}><FolderIcon /></div>
        : <div className={styles.treeItemFileIcon}><FileIcon /></div>
      }
      {
        <button onClick={() => isItemFolder(entry) ? onSelectFolder(entry.path) : onSelectFile(entry.path)}>{ entry.label }</button>
      }
    </div>
    <div className={styles.treeItemInfo}>
      { entry.contentType }
    </div>
    <div className={styles.treeItemInfoSize}>
      { entry.size && formatBytes(entry.size) }
    </div>
  </li>
)

const TreeView = ({ currentFiles, selectedFolder, onSelectFolder, onSelectFile }) => {
  if (!currentFiles) return null

  return (
    <div>
      <ul className={styles.treeList}>
        { selectedFolder &&
            selectedFolder !== '/' &&
            <TreeItem
              entry={{
                type: 'folder',
                path: getPrevPath({ path: selectedFolder, isFolder: true }),
                label: '../'
              }}
              key='../'
              onSelectFolder={onSelectFolder}
              onSelectFile={onSelectFile}
            />
        }
        {orderBy(
          Object
            .keys(currentFiles)
            .map(currentPath => currentFiles[currentPath]),
          ['type', 'label'],
          ['desc', 'asc']
        )
          .map(entry => {
            return <TreeItem
              entry={entry}
              key={entry.path}
              onSelectFolder={onSelectFolder}
              onSelectFile={onSelectFile}
            />
          })}
      </ul>
    </div>
  )
}

const getPrevPath = ({ path, isFolder }) =>
  `${path.split('/').slice(0, -(isFolder ? 2 : 1)).join('/')}/`

const getselectedFolderSubTree = (files, selectedFolder) =>
  Object.keys(files).reduce((acc, filename) => {
    const name = filename &&
      filename.replace(new RegExp(`^${selectedFolder}`), '')
    if (!name || name === filename) return acc

    const [currentFolderName, isFolder] = name.split('/')
    const { contentType, path, size, type } = files[filename]
    const label = isFolder ? `${currentFolderName}/` : name

    acc[label] = isFolder
      ? {
        contentType: 'folder',
        label,
        path: `${selectedFolder}${currentFolderName}/`,
        size: ((acc[label] && acc[label].size) || 0) + (size || 0),
        type: 'folder'
      } : {
        contentType,
        label,
        path,
        size,
        type
      }

    return acc
  }, {})

const CodeView = ({ currentFileContent, currentFileContentType, currentFileLOC, currentFileSize, onSelectBack }) => (
  <div className={styles.codeView}>
    <div className={styles.codeViewHeader}>
      <button className={styles.codeViewBackButton} onClick={() => onSelectBack()}>
        <div className={styles.codeViewBackIcon}><BackIcon /></div>
        Back
      </button>
      <div className={styles.codeViewHeaderFileInfo}>
        <div>{ currentFileLOC } LOC</div>
        <div className={styles.codeViewHeaderItem}>{ formatBytes(currentFileSize) }</div>
      </div>
    </div>
    <SyntaxHighlighter
      language={getSupportedLanguage(currentFileContentType)}
      showLineNumbers
      style={syntaxStyle}
      lineNumberStyle={{
        paddingLeft: 10,
        color: '#8c8c8c'
      }}
    >
      { currentFileContent }
    </SyntaxHighlighter>
  </div>
)

const getNoContentTitle = code => ({
  NOPACKAGE: 'Setting up files...',
  NOPACKAGEFILE: 'This file failed to load',
  BINARYFILE: 'This file type is not supported',
  FILECOUNTLIMITEXCEEDED: 'Number of files in the package exceeds configured limits',
  FILESIZELIMITEXCEEDED: 'Untarred size of the package exceeds configured limits'
})[code]

const NoContent = ({ code, files, selectedFilePath, packageName, selectedFolder, fetchFiles, fetchSelectedFile, handleSelectFolder }) => {
  if (!code) return null
  const title = getNoContentTitle(code) || 'Oh noes! Something went wrong.'
  const isIndexFileFetched = Object.keys(files).length === 0
  const onClickHandler = (isIndexFileFetched)
    ? () => fetchFiles()
    : () => fetchSelectedFile(selectedFilePath)

  const isBinary = selectedFilePath && files[selectedFilePath].isBinary === 'true'
  const errorCodes = new Set(['FILECOUNTLIMITEXCEEDED', 'FILESIZELIMITEXCEEDED'])
  const showRetry = !isBinary && !(errorCodes.has(code))

  return (
    <div>
      {!isIndexFileFetched &&
        <TreeBreadcrumb
          packageName={packageName}
          selectedFolder={selectedFolder}
          selectedFile={selectedFilePath}
          onSelectFolder={path => handleSelectFolder(path)}
        />
      }
      <div className={stylesV2.noContent}>
        <div className={stylesV2.Box}>
          <div className={stylesV2.blankslate}>
            {isIndexFileFetched &&
              <div className={stylesV2.spinner}>
                <Spinner color='black' size='64' />
              </div>
            }
            <h3 className={stylesV2['blankslate-heading']}>{title}</h3>
            {showRetry &&
              <div>
                {isIndexFileFetched && <p className={stylesV2.noContentText}>This happens only once on this package version and may take a few minutes to complete</p>}
                {!isIndexFileFetched && <p className={stylesV2.noContentText}>Please try again or <a className={stylesV2.noContentLink} href='https://npmjs.com/support'>contact us</a> if this issue persists.</p>}
                <div className={stylesV2['blankslate-action']}>
                  <a onClick={onClickHandler}
                    className={`${forms.button} ${forms.buttonGradient} ${stylesV2.noContentButton}`}>
                    {isIndexFileFetched ? 'Refresh' : 'Try Again'}
                  </a>
                </div>
              </div>}
          </div>
        </div>
      </div>
    </div>
  )
}

class FileExplorerV2 extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedFile: '',
      selectedFolder: '/',
      currentFileContent: '',
      files: {},
      loading: true,
      noContentCode: null
    }
  }

  handleSelectFolder (path) {
    this.setState({
      selectedFolder: path,
      selectedFile: '',
      currentFileContent: '',
      noContentCode: null,
      loading: false
    })
  }

  handleSelectFile (path) {
    this.setState({
      selectedFolder: null,
      selectedFile: path,
      loading: true
    })
    this.fetchSelectedFile(path)
  }

  fetchSelectedFile = (path) => {
    const isBinary = path && this.state.files[path] && this.state.files[path].isBinary === 'true'
    if (isBinary) {
      this.setState({
        noContentCode: 'BINARYFILE'
      })
      return
    }

    const pkgName = this.props.packageName
    const fileHex = path && this.state.files[path] && this.state.files[path].hex
    const route = `/package/${pkgName}/file/${fileHex}`
    window.fetch(route)
      .then(response => {
        if (!response.ok) {
          this.setState({
            noContentCode: 'NOPACKAGEFILE'
          })
          throw new Error('Something went wrong.')
        }
        return response.text()
      })
      .then(
        (result) => {
          this.setState({
            currentFileContent: result,
            loading: false,
            noContentCode: null
          })
        }
      )
      .catch((_error) => {
      })
  }

  handleSelectBack () {
    this.setState({
      selectedFolder: getPrevPath({ path: this.state.selectedFile }),
      selectedFile: '',
      currentFileContent: '',
      loading: false
    })
  }

  componentDidMount () {
    this.fetchFiles()
  }

  componentWillUnmount () {
    clearInterval(this.intervalID)
  }

  fetchFiles = () => {
    const route = `/package/${this.props.packageName}/v/${this.props.packageVersion}/index`
    window.fetch(route)
      .then(response => {
        if (!response.ok) {
          this.setState({
            noContentCode: 'NOPACKAGE'
          })
          if (!this.intervalID) {
            this.intervalID = setInterval(
              () => {
                this.fetchFiles()
              }, FETCH_INDEX_RETRY_INTERVAL)
          }
          throw new Error('Something went wrong.')
        }
        return response.json()
      })
      .then(
        (result) => {
          if (result.errorCode) {
            this.setState({
              noContentCode: result.errorCode.toUpperCase()
            })
            return
          }
          this.setState({
            files: result.files,
            loading: false,
            noContentCode: null
          })
          clearInterval(this.intervalID)
        }
      )
      .catch((_error) => {
      })
  }

  render () {
    const { packageName } = this.props
    const { files, selectedFile, selectedFolder, loading, noContentCode, currentFileContent } = this.state
    const { integrity, shasum } = files
    const currentFiles = selectedFolder && getselectedFolderSubTree(files, selectedFolder)
    const currentFile = files[selectedFile]
    const currentFileContentType = currentFile && currentFile.contentType
    const currentFileLOC = currentFile && currentFile.linesCount
    const currentFileSize = currentFile && currentFile.size

    return (
      <div>
        <div className={styles.fileExplorer}>
          {
            !noContentCode &&
              <TreeBreadcrumb
                packageName={packageName}
                selectedFolder={selectedFolder}
                selectedFile={selectedFile}
                onSelectFolder={path => this.handleSelectFolder(path)}
              />
          }
          {
            (!selectedFile && !noContentCode && loading) &&
            <div className={stylesV2.spinner}>
              <Spinner color='black' size='32' />
            </div>
          }
          { currentFiles && !noContentCode &&
          <TreeView
            currentFiles={currentFiles}
            selectedFolder={selectedFolder}
            onSelectFolder={path => this.handleSelectFolder(path)}
            onSelectFile={path => this.handleSelectFile(path)}
          />
          }
          {
            (selectedFile && !noContentCode && loading) &&
            <div className={stylesV2.spinner}>
              <Spinner color='black' size='32' />
            </div>
          }
          {
            currentFileContent && !noContentCode &&
              <CodeView
                currentFileContentType={currentFileContentType}
                currentFileContent={currentFileContent}
                currentFileLOC={currentFileLOC}
                currentFileSize={currentFileSize}
                onSelectBack={() => this.handleSelectBack()}
              />
          }
          {
            noContentCode &&
              <NoContent
                code={noContentCode}
                files={files}
                selectedFilePath={selectedFile}
                packageName={packageName}
                selectedFolder={selectedFolder}
                fetchFiles={() => this.fetchFiles()}
                fetchSelectedFile={path => this.fetchSelectedFile(path)}
                handleSelectFolder={path => this.handleSelectFolder(path)}
              />
          }
        </div>
        <div className={styles.shasumInfo}>
          {
            integrity &&
              <div><strong>Integrity:</strong> <div className={styles.shasumItem}>{ integrity }</div></div>
          }
          {
            shasum &&
              <div><strong>Shasum:</strong> <div className={styles.shasumItem}>{ shasum }</div></div>
          }
        </div>
      </div>
    )
  }
}

module.exports = FileExplorerV2
