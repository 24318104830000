'use strict'

const React = require('react')
const PropTypes = require('prop-types')
const DefinitelyTypedIcon = require('../../components/package/definitelytyped-icon')
const TypeScriptIcon = require('../../components/package/typescript-icon')

class TypesIcon extends React.PureComponent {
  render () {
    const { capsule = {}, pkgVersion } = this.props

    if (pkgVersion && (pkgVersion.types || pkgVersion.typings)) {
      // During feature rollout, previously published packages won't have a capsule with calculated types metadata
      return <TypeScriptIcon />
    }

    if (!capsule.types || !capsule.types.typescript) {
      return null
    }

    // Order matters; we prefer bundled types declaration over external (in a separate package) declarations
    if (capsule.types.typescript.bundled) {
      return <TypeScriptIcon />
    }
    if (capsule.types.typescript.package) {
      return <DefinitelyTypedIcon packageName={capsule.types.typescript.package} />
    }
    return null
  }
}

TypesIcon.propTypes = {
  capsule: PropTypes.shape({
    types: PropTypes.shape({
      typescript: PropTypes.shape({
        bundled: PropTypes.string,
        package: PropTypes.string
      })
    })
  }).isRequired,
  pkgVersion: PropTypes.shape({
    types: PropTypes.string,
    typings: PropTypes.string
  })
}

module.exports = TypesIcon
